// function loadFonts() {
// 	if (sessionStorage.fontsLoaded) {
// 		document.documentElement.classList.add('fonts-loaded');
// 		return;
// 	}

	if ('fonts' in document) {
		Promise.all([
			document.fonts.load('italic 300 1em futura-pt'),
			document.fonts.load('400 1em futura-pt'),
			document.fonts.load('500 1em futura-pt'),
			document.fonts.load('600 1em futura-pt'),
			document.fonts.load('700 1em futura-pt')
		]).then(_ => {
			document.documentElement.classList.add('fonts-loaded');

			// Optimization for Repeat Views
			// sessionStorage.fontsLoaded = true;
		});
	}
// }

// loadFonts();